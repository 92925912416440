<script setup lang="ts">
// Using i18n for translations
const { t } = useI18n();
const { locale } = useI18n();
// Defining page metadata
definePageMeta({
  layout: "landing",
  colorMode: "light",
});

// useHead({
//   script: [
//     {
//       innerHTML: `
//         window.$crisp=[];
//         window.CRISP_WEBSITE_ID="bebb2fd2-9f04-49ac-98a2-4b811db48d08";
//         (function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
//       `,
//       tagPosition: "head",
//     },
//   ],
// });
useHead({
  title: 'Menedzselt weboldalak saját adminnal',
  meta: [
    { name: 'description', content: 'Fedezze fel a menedzselt weboldalak előnyeit saját adminisztrációs felülettel. Professzionális weboldalkezelés, egyszerű tartalomfrissítés és teljes irányítás a webes jelenlét felett.' }
  ],
  link: [
    { rel: 'preconnect', href: 'https://flexi-solutions.net' },
    { rel: 'canonical', href: 'https://flexi-solutions.net' }
  ],
  script: [
    {
      innerHTML: `
        window.$crisp=[];
        window.CRISP_WEBSITE_ID="bebb2fd2-9f04-49ac-98a2-4b811db48d08";
        (async function() {
          const d = document;
          const s = d.createElement("script");
          s.src = "https://client.crisp.chat/l.js";
          s.async = 1;
          d.getElementsByTagName("head")[0].appendChild(s);
        })();
      `,
      tagPosition: "head",
    },
  ],
});

// Creating computed properties for SEO meta tags
const title = computed(() => t("seoTitle"));
const description = computed(() => t("seoDesc"));

// Setting SEO meta tags
useSeoMeta({
  title: title,
  description: description,
});

const pricing = computed(() => [
  {
    name: t("landingOnly"),
    price: t("landingOnlyPrice"),
    popular: false,
    features: [
      t("landingOnlyPages"),
      t("landingOnlyTemplates"),
      t("landingOnlySMIntegration"),
      // t('landingOnlySupport'),
      // t('landingOnlyDomain'),
      t("landingAdminServer"),
    ],
    button: {
      text: t("landingOnlyCTA"),
      link: "/#contact",
    },
  },
  {
    name: t("landingAdmin"),
    price: t("landingAdminPrice"),
    popular: true,
    features: [
      t("landingAdminPages"),
      t("LandingAdminTemplates"),
      t("landingAdminSMIntegration"),
      t("landingAdminSupport"),
      t("landingAdminDomain"),
      t("landingAdminServer"),
    ],
    button: {
      text: t("landingAdminCTA"),
      link: "/#contact",
    },
  },
  {
    name: t("landingAdminUnique"),
    price: t("landingAdminUniquePrice"),
    popular: false,
    features: [
      t("landingAdminUniquePages"), //
      t("LandingAdminUniqueTemplates"), //
      t("landingAdminUniqueSMIntegration"),
      t("landingAdminUniqueSupport"),
      t("landingAdminUniqueDomain"),
      t("landingAdminUniqueServer"),
      t("landingAdminUniqueSEO"),
    ],
    button: {
      text: t("landingAdminUniqueCTA"),
      link: "/#contact",
    },
  },
]);

const TeamImg1 = "/laszlo-kemeny.jpg";
const TeamImg2 = "/ross-sampson.jpg";
const TeamImg3 = "/gergely-lonyai.jpg";

const team = computed(() => [
  {
    name: "Kemény László",
    title: t("teamTitle1"),
    avatar: {
      src: TeamImg1,
      width: 480,
      height: 480,
    },
  },
  {
    name: "Ross Sampson",
    title: t("teamTitle2"),
    avatar: {
      src: TeamImg2,
      width: 480,
      height: 480,
    },
  },
  {
    name: "Lónyai Gergely",
    title: t("teamTitle3"),
    avatar: {
      src: TeamImg3,
      width: 480,
      height: 480,
    },
  },
]);
</script>

<template>
  <div class="max-w-screen-xl mx-auto px-5">
    <LandingNavbar />
    <LazyLandingHero />
    <LazyLandingFeatures />
    <LandingTemplates />

    <div id="pricing">
      <LazyLandingSectionhead>
        <template v-slot:title> {{ t("pricingTitle") }}</template>
        <template v-slot:desc> {{ t("pricingSubTitle") }} </template>
      </LazyLandingSectionhead>

      <div class="grid lg:grid-cols-3 gap-10 mx-auto max-w-screen-lg mt-12">
        <LazyLandingPricing v-for="item of pricing" :plan="item" />
      </div>
      <div id="order-process">
        <div class="grid mx-auto max-w-screen-lg mb-12">
          <h2 class="text-2xl font-bold text-gray-800 text-center">
            {{ t("pricingOrderProcessTitle") }}
          </h2>
          <p class="text-gray-800 text-center">
            {{ t("pricingOrderProcessSubTitle") }}
          </p>
        </div>
      </div>
    </div>

    <div id="about">
      <!-- <LazyLandingSectionhead>
        <template v-slot:title>{{ $t("aboutTitle") }}</template>
        <template v-slot:desc>{{ $t("aboutDesc") }}</template>
      </LazyLandingSectionhead> -->

      <div class="flex flex-col gap-3 mx-auto max-w-4xl mt-16">
        <h2 class="font-bold text-3xl text-gray-800">
          {{ $t("aboutTitle2") }}
        </h2>
        <p class="text-lg leading-relaxed text-slate-500">
          {{ $t("aboutDesc2") }}
        </p>
      </div>
      <div class="grid md:grid-cols-3 gap-10 mx-auto max-w-4xl mt-12">
        <div v-for="item of team" class="group">
          <div class="w-full">
            <NuxtImg
              :src="item.avatar.src"
              :width="item.avatar.width"
              :height="item.avatar.height"
              format="webp"
              :alt="item.title"
              class="w-full h-full object-cover rounded-full transition group-hover:-translate-y-1 group-hover:shadow-xl"
            />
          </div>

          <div class="mt-4 text-center">
            <h2 class="text-lg text-gray-800">{{ item.name }}</h2>
            <h3 class="text-sm text-slate-500">{{ item.title }}</h3>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div id="contact">
            <LazyLandingContact />
          </div>

          <LazyLandingLogos />
          <LazyLandingCta />
        </div>
      </div>
    </div>
  </div>
</template>
